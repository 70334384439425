import './Especialidades.css'

function Especialidades(props) {

  const cards = [{
    img: process.env.PUBLIC_URL + '/imgs/divorcios.svg',
    title: 'Divorcios',
    height: '50px'
  },
  {
    img: process.env.PUBLIC_URL + '/imgs/comunicacion.svg',
    title: 'Régimen de comunicación',
    height: '40px'
  },
  {
    img: process.env.PUBLIC_URL + '/imgs/alimentos.svg',
    title: 'Alimentos',
    height: '50px'
  },
  {
    img: process.env.PUBLIC_URL + '/imgs/sucesiones.svg',
    title: 'Sucesiones',
    height: '50px'
  },
  {
    img: process.env.PUBLIC_URL + '/imgs/laboral.svg',
    title: 'Derecho laboral/Accidentes de trabajo',
    height: '50px'
  }]

  const onCardClick = cardTitle => {
    props.setEspecialidad(cardTitle)
    window.scrollTo(0,document.body.scrollHeight);
  }

  return (
    <div className="Especialidades" style={{ backgroundImage: 'linear-gradient(to bottom, rgba(166, 166, 162, .5), rgba(2, 39, 92, .9)), url(' + process.env.PUBLIC_URL + '/imgs/textura.png)' }}>
      <h2>NOS ESPECIALIZAMOS EN</h2>
        <div className="Especialidades-content">
            {cards.map((card, index) => <div className="Especialidades-element" key={index} onClick={() => onCardClick(card.title)}><div className="Especialidades-element-img"><img src={card.img} alt="" style={{ height: card.height }} /></div><div className="Especialidades-element-title"><h4>{card.title}</h4></div></div>)}
        </div>

    </div>
  )

}

export default Especialidades