import './App.css'
import Portada from './components/Portada'
import Nosotros from './components/Nosotros'
import Especialidades from './components/Especialidades'
import Contacto from './components/Contacto'
import { useState } from 'react'

function App() {

  const [especialidad, setEspecialidad] = useState('')

  return (
    <div className="App">
      <Portada />
      <Nosotros />
      <Especialidades setEspecialidad={setEspecialidad} />
      <Contacto especialidad={especialidad} />
      <div className="App-footer"><p>Vera Nayar Abogada</p></div>
    </div>
  )

}

export default App