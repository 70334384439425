import './Nosotros.css'

function Nosotros() {
  return (
    <div className="Nosotros">
      <h2>Estudio Jurídico especializado en Derecho de Familia y Sucesiones.</h2>
      <p>En Estudio Nayar estamos preparados y formados para brindar el mejor asesoramiento legal con la <b>calidez humana</b> que nos caracteriza.
        <br /><br />
        Entendemos que el área del <b>derecho de familia</b> implica tener en cuenta los procesos emocionales y afectivos por los que se ve atravesado el cliente, y es por ello que nuestro objetivo será encontrar la mejor solución posible para cada caso que nos sea consultado.
        <br /><br />
        Vera Nayar, egresada de la Facultad de Derecho de la Universidad de Buenos Aires, ha creado este estudio con el fin de representar a cada cliente de forma <b>eficaz e integral</b>, abarcando todas las situaciones relacionadas con la familia, incluyendo <b>divorcios</b>, <b>cuotas alimentarias</b> y <b>sucesiones</b>, entre otras.
        <br /><br />
        Otras áreas en las que prestamos asistencia y asesoramiento a todos nuestros clientes son <b>derecho laboral</b> y <b>accidentes de trabajo</b>, tanto en instancias administrativas como judiciales.</p>
    </div>
  )
}

export default Nosotros