import { useEffect, useState } from 'react'
import './Contacto.css'

function Contacto(props) {

  const [showModal, setShowModal] = useState(false)

  const [nombre, setNombre] = useState('')
  const [motivo, setMotivo] = useState('')
  const [telefono, setTelefono] = useState('')
  const [email, setEmail] = useState('')
  const [mensaje, setMensaje] = useState('')

  useEffect(() => {
    if (props.especialidad !== '') {
      setMotivo(props.especialidad)
    }
  }, [props.especialidad])

  const submitForm = e => {
    e.preventDefault()
    if (nombre !== '' && motivo !== '' && telefono !== '' && email !== '' && mensaje !== '') {
      setShowModal(true)
      const scrollY = window.scrollY
      document.body.style.position = 'fixed'
      document.body.style.top = '-' + scrollY + 'px'

      const veraNayarForm = {}

      veraNayarForm.nombre = nombre
      veraNayarForm.motivo = motivo
      veraNayarForm.telefono = telefono
      veraNayarForm.email = email
      veraNayarForm.mensaje = mensaje

      const jsonData = JSON.stringify(veraNayarForm)

      let xhr = new XMLHttpRequest()
      xhr.open('POST', 'https://newsletterservice.com.ar/landing/api/estudionayarweb')

      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            alert("Formulario enviado con éxito. Lo contactaremos a la brevedad.")
          } else {
            alert("Error al enviar el formulario, por favor vuelva a intentarlo o comuníquese por otra vía.")
          }
          setShowModal(false)
          const scrollY = document.body.style.top
          document.body.style.position = ''
          document.body.style.top = ''
          window.scrollTo(0, parseInt(scrollY || '0') * -1)
        }
      }
      xhr.setRequestHeader('Content-Type', 'application/json')
      xhr.send(jsonData)

    } else {
      alert('Por favor, complete todos los campos antes de enviar el formulario.')
    }
  }

  return (
    <div className="Contacto">
      <div className="Contacto-content">
        <p><h2>CONTÁCTENOS</h2><br /><br />Actualmente es posible coordinar entrevistas presenciales o virtuales, a preferencia de cada cliente.<br /><br /><b>No dude en consultarnos.</b><p><img src={process.env.PUBLIC_URL + '/imgs/tel.svg'} alt="" />+54 9 11 2259-6780</p><p><img src={process.env.PUBLIC_URL + '/imgs/lugar.svg'} alt="" />Lavalle 1537, 1D, CABA</p></p>
        <form className="Contacto-form" onSubmit={submitForm}>
          <p>Complete el formulario y nos comunicaremos a la brevedad</p>
          <input type="text" value={nombre} onChange={e => setNombre(e.target.value)} placeholder="Nombre" ></input>
          <input type="text" value={motivo} onChange={e => setMotivo(e.target.value)} placeholder="Motivo de consulta" ></input>
          <input type="tel" value={telefono} onChange={e => setTelefono(e.target.value)} placeholder="Teléfono" ></input>
          <input type="email" value={email} onChange={e => setEmail(e.target.value)} placeholder="Email" ></input>
          <textarea value={mensaje} onChange={e => setMensaje(e.target.value)} ></textarea>
          <input className="Contacto-form-submit" type="submit" value='Enviar' ></input>
          {
            showModal ?
              <div className="Contacto-modal">
                <img src={process.env.PUBLIC_URL + '/imgs/cargando.gif'} alt="Cargando..." />
                <p>Enviando el formulario, por favor aguarde</p>
              </div>
              : null
          }
        </form>
      </div>
      <div className="Contacto-social">
        <a href="https://www.instagram.com/veranayar.abogada" target="_blank" rel="noreferrer"><img src={process.env.PUBLIC_URL + '/imgs/ig.svg'} alt="Instagram" /></a>
        <a href="https://api.whatsapp.com/send?phone=5491122596780" target="_blank" rel="noreferrer"><img src={process.env.PUBLIC_URL + '/imgs/wa.svg'} alt="WhatsApp" /></a>
      </div>
    </div>
  )

}

export default Contacto