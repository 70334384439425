import './Portada.css'

function Portada() {
  return (
    <div className="Portada" style={{ backgroundImage: 'linear-gradient(to bottom, rgba(166, 166, 162, 0.73), rgba(2, 39, 92, 0.73)), url(' + process.env.PUBLIC_URL + '/imgs/portada.jpg)' }}>
      <img src={process.env.PUBLIC_URL + '/imgs/logo.png'} alt="" />
      <h1>VERA NAYAR ABOGADA</h1>
    </div>
  )
}

export default Portada